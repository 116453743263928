import React, { useState } from "react"

//Redux
import { useSelector } from "react-redux"

//Components
import Section from "../../components/Tools/Section/Section"
import CommunicationBar from "../../components/Tools/CommunicationBar/CommunicationBar"
import FeatureContainer from "../../components/Tools/FeatureContainer/FeatureContainer"

//Localized
import billPayData from "../../localized/billPayLocalized.json"
import communicationBarData from "../../localized/communicationBarLocalized.json"

// Icons
import emailPayIcon from "../../assets/company/email-pay-icon.svg"
import setPoliciesICon from "../../assets/company/set-policies-icon.svg"
import Layout from "../../components/Layout"

const BillPay = () => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  //Email variable
  const [contactEmail, setContactEmail] = useState("")

  const billPayFeaturesData = [
    {
      cardIcon: emailPayIcon,
      cardTitle: billPayData.secondSectionFirstCardTitle?.[currentLanguage],
      cardDescription:
        billPayData.secondSectionFirstCardDescription?.[currentLanguage],
    },
    {
      cardIcon: setPoliciesICon,
      cardTitle: billPayData.secondSectionSecondCardTitle?.[currentLanguage],
      cardDescription:
        billPayData.secondSectionSecondCardDescription?.[currentLanguage],
    },
  ]

  const sectionOne = (
    <div id="bill-pay-first-section" className='w-100'>
      <div className="section_paragraph">
        <span
          data-wow-duration="1s"
          className="wow animate__fadeInDown section_paragraph_top"
          id="third-section-title"
        >
          {billPayData.invoiceWrestling?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeInDown section_paragraph_middle"
          data-wow-duration="1.5s"
          id="third-section-subtitle"
        >
          {billPayData.automateInvoicePayments?.[currentLanguage]}
        </span>
        <div
          className="wow animate__fadeInDown section_paragraph_bottom"
          data-wow-duration="2s"
        >
          <span>{billPayData.forwardOrPolicies?.[currentLanguage]}</span>
        </div>
      </div>
      <FeatureContainer data={billPayFeaturesData} />
    </div>
  )

  const sectionTwo = (
    <div id="bill-pay-second-section" className="w-100">
      <div
        className="wow animate__fadeIn communication_bar_container"
        data-wow-duration="2s"
      >
        <div className="communication_bar_header">
          {`${communicationBarData.beTheFirst?.[currentLanguage]} !`}
        </div>
        <CommunicationBar
          id="bill-pay-waitlist-bar"
          inputValue={contactEmail}
          changeInputValue={setContactEmail}
          navigateLink={`/waitlist/?lang=${currentLanguage}`}
          type="joinWaitlist"
        />
      </div>
    </div>
  )

  return (
    <Layout>
      <div id="bill-pay">
        <Section
          backgroundColor="white"
          sectionContent={sectionOne}
          sectionId="bill-pay-first-section-wrapper"
          topSection={true}
        />
        <Section
          backgroundColor="white"
          sectionContent={sectionTwo}
          sectionId="bill-pay-second-section-wrapper"
          otherClassName="pt-0"
        />
      </div>
    </Layout>
  )
}

export default BillPay
